import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Loading, ProductShow } from 'components';

import { FetchActions } from 'types';
import useSWR from 'swr';
import { fetchAllProductData } from 'utils/api/fetch-all-product-data';
import { useRecommendation } from 'utils/hooks/use-recommendation';
import { Flex, Title } from '@mantine/core';
import { recommendationParamsCheck } from 'utils/recommendations';

const RecommendationsProduct = () => {
  const history = useHistory();
  const { location } = history;

  const { productId, recommendationId = '' } = useParams<{ recommendationId: string; productId: string }>();

  const { recommendation, error, isLoading: isLoadingRecommendation } = useRecommendation({ token: recommendationId });

  useEffect(() => {
    (async () => {
      await recommendationParamsCheck(location);
    })();
  }, [location]);

  const { data, isLoading, mutate } = useSWR(
    recommendation ? [`${FetchActions.ALL_PRODUCT_DATA}-${productId}`, recommendation] : null,
    () => fetchAllProductData(Number(productId), 'recommendation', recommendation),
  );

  const productData = data || {};

  if (isLoading || isLoadingRecommendation) {
    return (
      <section className="container pt-10">
        <div className="row">
          <Loading />
        </div>
      </section>
    );
  }

  if (recommendation && productData.product) {
    return <ProductShow recommendation={recommendation} productData={productData} mutate={mutate} />;
  }

  if (error) {
    return (
      <Flex justify="center" align="center" mih={500}>
        <Title order={4} className="mb-3">
          This recommendation has expired or is invalid.
        </Title>
      </Flex>
    );
  }
};

export default RecommendationsProduct;
